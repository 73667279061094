import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS } from '../const.config';

// Subscriptions module configurations
export const LISTING_COLUMNS = [
  {
    id: 'name',
    label: 'Name',
    width: '10%',
    align: 'left',
    dataKey: 'name',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'email',
    label: 'Email',
    width: '15%',
    align: 'left',
    dataKey: 'email',
    sortable: true,
    type: CELL_DATA_TYPES.EMAIL,
  },
  {
    id: 'videoTitle',
    label: 'Video',
    width: '10%',
    align: 'left',
    dataKey: 'videoTitle',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'platform',
    label: 'Platform',
    width: '10%',
    align: 'left',
    dataKey: 'platform',
    sortable: true,
    type: CELL_DATA_TYPES.PLATFORM,
  },
  {
    id: 'startDate',
    label: 'Start Date',
    width: '15%',
    align: 'left',
    dataKey: 'startDate',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'endDate',
    label: 'End Date',
    width: '15%',
    align: 'left',
    dataKey: 'endDate',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'totalAmount',
    label: 'Actual Amount',
    width: '2%',
    align: 'center',
    dataKey: 'totalAmount',
    sortable: true,
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'discount',
    label: 'Discount',
    width: '2%',
    align: 'center',
    dataKey: 'discount',
    sortable: true,
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'payableAmount',
    label: 'Paid Amount',
    width: '2%',
    align: 'center',
    dataKey: 'payableAmount',
    sortable: true,
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'entryType',
    label: 'Type',
    width: '7%',
    align: 'left',
    dataKey: 'entryType',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'status',
    label: 'Status',
    width: '7%',
    align: 'center',
    dataKey: 'status',
    sortable: true,
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'createTime',
    label: 'Created At',
    width: '15%',
    align: 'left',
    dataKey: 'createTime',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
];

export const VIEW_COLUMNS = [
  {
    id: 'name',
    label: 'Name',
    dataKey: 'name',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'email',
    label: 'Email',
    dataKey: 'email',
    type: CELL_DATA_TYPES.EMAIL,
  },
  {
    id: 'videoTitle',
    label: 'Video',
    dataKey: 'videoTitle',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'platform',
    label: 'Platform',
    dataKey: 'platform',
    type: CELL_DATA_TYPES.PLATFORM,
  },
  {
    id: 'startDate',
    label: 'Start Date',
    dataKey: 'startDate',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'endDate',
    label: 'End Date',
    dataKey: 'endDate',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'totalAmount',
    label: 'Actual Amount',
    dataKey: 'totalAmount',
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'discount',
    label: 'Discount',
    dataKey: 'discount',
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'promoCode',
    label: 'Promo Code',
    dataKey: 'promoCode',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'payableAmount',
    label: 'Paid Amount',
    dataKey: 'payableAmount',
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'entryType',
    label: 'Type',
    dataKey: 'entryType',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'status',
    label: 'Status',
    dataKey: 'status',
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'createTime',
    label: 'Created At',
    dataKey: 'createTime',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'notes',
    label: 'Notes',
    dataKey: 'notes',
    type: CELL_DATA_TYPES.TEXT,
  },
];
