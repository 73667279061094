import axios from 'axios';
import prepareRequestOptions from '../utils/admin-apis.util';

export const getListData = async (params = '') => {
  const options = prepareRequestOptions(
    `/admin/videos/list${params}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const createNewData = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/videos/create',
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const viewDataById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/videos/${id}/view`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const getChoicesById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/videos/${id}/choices`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const getChoiceListByParentId = async (id, queryParams) => {
  const options = prepareRequestOptions(
    `/admin/videos/${id}/choiceList${queryParams}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const updateDataById = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/videos/${id}/update`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const deleteDataById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/videos/${id}/delete`,
    'DELETE',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const assignChoiceToVideo = async (payload) => {
  const options = prepareRequestOptions(
    `/admin/videos/choice/create`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const deleteChoiceFromVideo = async (id) => {
  const options = prepareRequestOptions(
    `/admin/videos/${id}/choice/delete`,
    'DELETE',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const uploadImageToBunnyCDN = async (payload) => {
  const options = prepareRequestOptions(
    `/admin/upload/image/cdn`,
    'POST',
    payload,
    true,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const uploadVideoToBunnyCDN = async (payload) => {
  const options = prepareRequestOptions(
    `/admin/upload/video/cdn`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const getPlayableStream = async (uri) => {
  const options = prepareRequestOptions(
    `/admin/get/playable-stream?${uri}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const syncVideoCDNStatus = async (contentType) => {
  const options = prepareRequestOptions(
    `/admin/video/${contentType}/cdn/sync`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const getVideoAvailability = async (id) => {
  const options = prepareRequestOptions(
    `/admin/video/${id}/availability/get`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const updateVideoAvailability = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/video/${id}/availability/update`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const getCountriesList = async (params = '') => {
  const options = prepareRequestOptions(
    `/admin/countries/search-list${params}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const getReviewsList = async (id, params = '') => {
  const options = prepareRequestOptions(
    `/admin/video/${id}/reviews${params}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const videoReview = async (id, payload) => {
  const url = `/admin/videos/${id}/review`;
  const options = prepareRequestOptions(url, 'POST', payload, true);
  const response = await axios(options);
  return response.data;
};
