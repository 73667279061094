import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS } from '../const.config';

// sku module configurations
export const LISTING_COLUMNS = [
  {
    id: 'code',
    label: 'Code',
    width: '30%',
    align: 'left',
    dataKey: 'code',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'price',
    label: 'Price',
    width: '30%',
    align: 'left',
    dataKey: 'price',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'createTime',
    label: 'Created At',
    width: '30%',
    align: 'left',
    dataKey: 'createTime',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
];

export const VIEW_COLUMNS = [
  {
    id: 'code',
    label: 'Code',
    dataKey: 'code',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'price',
    label: 'Price',
    dataKey: 'price',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'createTime',
    label: 'Created At',
    dataKey: 'createTime',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'modifiedTime',
    label: 'Modified At',
    dataKey: 'modifiedTime',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];
