import React from 'react';
import { useParams } from 'react-router-dom';
import AppLayout from '../../layout/app.layout';
import ManageReviews from '../../components/videos/manage-reviews.component';

const ContentVideoReviews = () => {
  const { id } = useParams();
  const pageTitle = 'Content / Videos / Manage Reviews';

  return (
    <AppLayout pageTitle={pageTitle}>
      <ManageReviews dataId={id || 0} />
    </AppLayout>
  );
};

export default ContentVideoReviews;
