import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS } from '../const.config';

// Promo codes module configurations
export const LISTING_COLUMNS = [
  {
    id: 'code',
    label: 'Code',
    width: '12%',
    align: 'left',
    dataKey: 'code',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'validFrom',
    label: 'Valid From',
    width: '15%',
    align: 'left',
    dataKey: 'validFrom',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'validTo',
    label: 'Valid To',
    width: '15%',
    align: 'left',
    dataKey: 'validTo',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'offer',
    label: 'Offer',
    width: '30%',
    align: 'left',
    dataKey: 'offer',
    sortable: false,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'isUsed',
    label: 'Usage',
    width: '5%',
    align: 'center',
    dataKey: 'isUsed',
    sortable: false,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'status',
    label: 'Status',
    width: '10%',
    align: 'center',
    dataKey: 'status',
    sortable: true,
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    width: '15%',
    align: 'right',
    dataKey: 'createdAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.EDIT.value,
    label: ACTIONS.EDIT.label,
  },
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
  {
    action: ACTIONS.DELETE.value,
    label: ACTIONS.DELETE.label,
    conditional: (val) => !val.isUsed,
  },
];

export const VIEW_COLUMNS = [
  {
    id: 'code',
    label: 'Code',
    dataKey: 'code',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'validFrom',
    label: 'Valid From',
    dataKey: 'validFrom',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'validTo',
    label: 'Valid To',
    dataKey: 'validTo',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'isUsed',
    label: 'Usage',
    dataKey: 'isUsed',
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'offer',
    label: 'Offer',
    dataKey: 'offer',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'notes',
    label: 'Notes',
    dataKey: 'notes',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'status',
    label: 'Status',
    dataKey: 'status',
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    dataKey: 'createdAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];
