import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListSMTPTemplates from '../../components/smtp/list-smtp-templates.component';

const SMTPTemplatesView = () => {
  const pageTitle = 'Settings / Email Templates';

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListSMTPTemplates />
    </AppLayout>
  );
};

export default SMTPTemplatesView;
