import React from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import {
  Box,
  List,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  ListSubheader,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  LS_ADMIN_USER_ROLE,
  VALID_ADMIN_ROLES,
} from '../../../config/const.config';
import ROUTE_PATH from '../../../config/routes.config';
import { getLocalValue } from '../../../utils/local-cache.util';
import { PRIMARY } from '../../../theme/theme-colors';
import Iconify from '../../common/iconify.component';

const StyledSubHeader = styled((props) => <ListSubheader {...props} />)(() => ({
  background: 'inherit',
  color: 'white',
  textDecoration: 'underline',
}));

const StyledNavItem = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  color: 'white',
  borderRadius: theme.shape.borderRadius,
}));

const StyledNavItemIcon = styled(ListItemIcon)({
  width: 24,
  height: 24,
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: 24,
});

const NavItem = ({ title, path, icon }) => (
  <StyledNavItem
    component={RouterLink}
    to={path}
    sx={{
      '&.active': {
        color: '#050514',
        bgcolor: PRIMARY.main,
        fontWeight: 'fontWeightBold',
        '& .MuiListItemIcon-root': {
          color: '#050514',
        },
      },
    }}
  >
    <StyledNavItemIcon>{icon}</StyledNavItemIcon>
    <ListItemText disableTypography primary={title} />
  </StyledNavItem>
);
NavItem.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
};

const NavItems = () => {
  const accessRole = getLocalValue(LS_ADMIN_USER_ROLE);

  return (
    <Box>
      <List disablePadding sx={{ p: 1 }}>
        <NavItem
          title="Dashboard"
          path={ROUTE_PATH.DASHBOARD}
          icon={<Iconify icon="ic:round-dashboard" />}
        />

        <StyledSubHeader component="div">Content</StyledSubHeader>
        <NavItem
          title="Categories"
          path={ROUTE_PATH.CONTENT_CATEGORIES}
          icon={<Iconify icon="ic:twotone-checklist" />}
        />
        <NavItem
          title="Videos"
          path={ROUTE_PATH.CONTENT_VIDEOS}
          icon={<Iconify icon="octicon:video-16" />}
        />
        <NavItem
          title="Ads"
          path={ROUTE_PATH.CONTENT_ADS}
          icon={<Iconify icon="ion:analytics-sharp" />}
        />
        <NavItem
          title="Comments"
          path={ROUTE_PATH.CONTENT_COMMENTS}
          icon={<Iconify icon="tabler:message-circle-cog" />}
        />

        <StyledSubHeader component="div">User Management</StyledSubHeader>
        <NavItem
          title="All Users"
          path={ROUTE_PATH.SUBSCRIBERS}
          icon={<Iconify icon="ci:users" />}
        />

        <NavItem
          title="Purchases"
          path={ROUTE_PATH.BILLING_PAYMENTS}
          icon={<Iconify icon="ic:outline-payments" />}
        />

        <StyledSubHeader component="div">Reports</StyledSubHeader>
        <NavItem
          title="Reports"
          path={ROUTE_PATH.REPORTS_OVERVIEW}
          icon={<Iconify icon="ic:outline-analytics" />}
        />
        <NavItem
          title="Realtime Users"
          path={ROUTE_PATH.REPORTS_LIVE_USERS}
          icon={<Iconify icon="ic:round-live-tv" />}
        />
        <NavItem
          title="Views by Session (Video)"
          path={ROUTE_PATH.REPORTS_SESSIONS}
          icon={<Iconify icon="ic:outline-list-alt" />}
        />
        <NavItem
          title="Views by Session (App)"
          path={ROUTE_PATH.REPORTS_APP_SESSIONS}
          icon={<Iconify icon="ion:analytics-sharp" />}
        />

        {[VALID_ADMIN_ROLES.SUPER_ADMIN, VALID_ADMIN_ROLES.ADMIN].includes(
          accessRole
        ) && (
          <>
            <StyledSubHeader component="div">Settings</StyledSubHeader>
            <NavItem
              title="SKU"
              path={ROUTE_PATH.SKU}
              icon={<Iconify icon="streamline:subscription-cashflow" />}
            />
            <NavItem
              title="Promo Codes"
              path={ROUTE_PATH.PROMO_CODES}
              icon={<Iconify icon="mdi:coupon" />}
            />
            <NavItem
              title="Email Templates"
              path={ROUTE_PATH.SMTP_TEMPLATES}
              icon={<Iconify icon="mdi:email-edit-outline" />}
            />
            {accessRole === VALID_ADMIN_ROLES.SUPER_ADMIN && (
              <NavItem
                title="Admin Access"
                path={ROUTE_PATH.ADMIN_USERS}
                icon={<Iconify icon="mdi:users-check" />}
              />
            )}
            <NavItem
              title="Email Settings"
              path={ROUTE_PATH.SMTP_SETTINGS}
              icon={<Iconify icon="ic:outline-mail" />}
            />
          </>
        )}
      </List>
    </Box>
  );
};

export default NavItems;
