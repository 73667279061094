import CELL_DATA_TYPES from '../cell-types.config';

// Manage Reviews module configurations
export const LISTING_COLUMNS = [
  {
    id: 'username',
    label: 'Username',
    width: '15%',
    align: 'left',
    dataKey: 'username',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'email',
    label: 'Email',
    width: '26%',
    align: 'left',
    dataKey: 'email',
    sortable: true,
    type: CELL_DATA_TYPES.EMAIL,
  },
  {
    id: 'like',
    label: 'Like',
    width: '15%',
    align: 'left',
    dataKey: 'like',
    sortable: true,
    type: CELL_DATA_TYPES.LIKE,
  },
  {
    id: 'dislike',
    label: 'Dislike',
    width: '15%',
    align: 'left',
    dataKey: 'dislike',
    sortable: true,
    type: CELL_DATA_TYPES.DISLIKE,
  },
];

export const LISTING_ACTIONS = [];
