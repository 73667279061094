import React from 'react';
import PropTypes from 'prop-types';
import { useRoutes, Navigate } from 'react-router-dom';
import { VALID_ADMIN_ROLES } from '../config/const.config';
import ROUTE_PATH from '../config/routes.config';
import LoginView from '../views/auth/login.view';
import DashboardView from '../views/dashboard/dashboard.view';
import ContentCategoriesView from '../views/content/categories.view';
import ContentVideosView from '../views/content/videos.view';
import ContentAdsView from '../views/content/ads.view';
import SubscribersView from '../views/subscribers/subscribers.view';
import SMTPSettingsView from '../views/smtp/smtp-settings.view';
import AdminUsersView from '../views/admin-users/admin-users.view';
import BillingPaymentsView from '../views/billing/payments.view';
import ReportsOverviewView from '../views/reports/overview.view';
import ReportsLiveUsersView from '../views/reports/live-users.view';
import ReportsSessionsView from '../views/reports/sessions.view';
import ReportsAppSessionsView from '../views/reports/app-sessions.view';
import PageNotFoundView from '../views/errors/not-found.view';
import LogoutView from '../views/auth/logout.view';
import ContentVideoAddEdit from '../views/content/video.add-edit';
import ContentVideoChoices from '../views/content/video.choices';
import ContentCategoriesManageVideos from '../views/content/categories.manage-videos';
import ContentCategoriesVideoOrder from '../views/content/categories.video-order';
import SKUSettingsView from '../views/sku/sku-settings.view';
import ContentCommentsView from '../views/content/comment.view';
import PromoCodesView from '../views/promo-codes/promo-codes.view';
import SMTPTemplatesView from '../views/smtp/smtp-templates.view';
import ContentVideoReviews from '../views/content/video.reviews';

const AppRouter = ({ isLoggedIn, accessRole }) => {
  const routeMappings = [];

  if (isLoggedIn) {
    routeMappings.push({
      path: ROUTE_PATH.LOGIN,
      element: <Navigate to={ROUTE_PATH.DASHBOARD} />,
      exact: true,
    });

    routeMappings.push({
      path: ROUTE_PATH.DASHBOARD,
      element: <DashboardView />,
      exact: true,
    });

    routeMappings.push({
      path: ROUTE_PATH.SUBSCRIBERS,
      element: <SubscribersView />,
      exact: true,
    });

    routeMappings.push({
      path: ROUTE_PATH.BILLING_PAYMENTS,
      element: <BillingPaymentsView />,
      exact: true,
    });

    routeMappings.push({
      path: ROUTE_PATH.REPORTS_OVERVIEW,
      element: <ReportsOverviewView />,
      exact: true,
    });

    routeMappings.push({
      path: ROUTE_PATH.REPORTS_LIVE_USERS,
      element: <ReportsLiveUsersView />,
      exact: true,
    });

    routeMappings.push({
      path: ROUTE_PATH.REPORTS_SESSIONS,
      element: <ReportsSessionsView />,
      exact: true,
    });

    routeMappings.push({
      path: ROUTE_PATH.REPORTS_APP_SESSIONS,
      element: <ReportsAppSessionsView />,
      exact: true,
    });

    routeMappings.push({
      path: ROUTE_PATH.LOGOUT,
      element: <LogoutView />,
      exact: true,
    });

    if (
      [VALID_ADMIN_ROLES.SUPER_ADMIN, VALID_ADMIN_ROLES.ADMIN].includes(
        accessRole
      )
    ) {
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_CATEGORIES,
        element: <ContentCategoriesView />,
        exact: true,
      });
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_CATEGORIES_MANAGE_VIDEOS,
        element: <ContentCategoriesManageVideos />,
        exact: true,
      });
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_CATEGORIES_MANAGE_VIDEO_ORDER,
        element: <ContentCategoriesVideoOrder />,
        exact: true,
      });

      routeMappings.push({
        path: ROUTE_PATH.CONTENT_VIDEOS,
        element: <ContentVideosView />,
        exact: true,
      });

      routeMappings.push({
        path: ROUTE_PATH.CONTENT_VIDEOS_NEW,
        element: <ContentVideoAddEdit />,
        exact: true,
      });
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_VIDEOS_EDIT,
        element: <ContentVideoAddEdit />,
        exact: true,
      });
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_VIDEOS_CHOICES,
        element: <ContentVideoChoices />,
        exact: true,
      });
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_VIDEOS_LIKE_DISLIKE,
        element: <ContentVideoReviews />,
        exact: true,
      });

      routeMappings.push({
        path: ROUTE_PATH.CONTENT_ADS,
        element: <ContentAdsView />,
        exact: true,
      });

      routeMappings.push({
        path: ROUTE_PATH.CONTENT_COMMENTS,
        element: <ContentCommentsView />,
        exact: true,
      });

      routeMappings.push({
        path: ROUTE_PATH.SKU,
        element: <SKUSettingsView />,
        exact: true,
      });

      routeMappings.push({
        path: ROUTE_PATH.PROMO_CODES,
        element: <PromoCodesView />,
        exact: true,
      });

      routeMappings.push({
        path: ROUTE_PATH.SMTP_TEMPLATES,
        element: <SMTPTemplatesView />,
        exact: true,
      });

      routeMappings.push({
        path: ROUTE_PATH.SMTP_SETTINGS,
        element: <SMTPSettingsView />,
        exact: true,
      });
    }

    if (accessRole === VALID_ADMIN_ROLES.SUPER_ADMIN) {
      routeMappings.push({
        path: ROUTE_PATH.ADMIN_USERS,
        element: <AdminUsersView />,
        exact: true,
      });
    }
  }

  routeMappings.push({
    path: '*',
    element: isLoggedIn ? <PageNotFoundView /> : <LoginView />,
  });

  const routes = useRoutes(routeMappings);

  return routes;
};

AppRouter.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  accessRole: PropTypes.string.isRequired,
};

export default AppRouter;
