import axios from 'axios';
import prepareRequestOptions from '../utils/admin-apis.util';

export const viewMailData = async (type) => {
  const options = prepareRequestOptions(
    `/admin/smtp/template/${type}/view`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const updateMailData = async (type, payload) => {
  const options = prepareRequestOptions(
    `/admin/smtp/template/${type}/update`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};
