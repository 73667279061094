import ROUTE_PATH from '../config/routes.config';

const videoManageReviewsUrl = (id) => {
  const url = ROUTE_PATH.CONTENT_VIDEOS_LIKE_DISLIKE;
  if (id) {
    return url.replace(':id', id);
  }
  return url;
};

export default videoManageReviewsUrl;
