import React, { useState, useEffect } from 'react';
import { Card, Stack, Button, Drawer, Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  LISTING_COLUMNS,
  LISTING_ACTIONS,
} from '../../config/module-configs/manage-reviews.config';
import ListData from '../table-elements/list-data.component';
import Iconify from '../common/iconify.component';
import { getReviewsList } from '../../services/videos.service';
import FilterReviews from './filter-reviews.component';
import ROUTE_PATH from '../../config/routes.config';

const ManageReviews = ({ dataId }) => {
  const columns = LISTING_COLUMNS;
  const actions = LISTING_ACTIONS;

  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 10,
    totalRows: 0,
    rows: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    error: false,
    loading: true,
    appliedFilters: {
      search: '',
    },
    filtersCounter: 0,
    videoTitle: '',
    likeCount: 0,
    disLikeCount: 0,
  });
  const [doAction, setDoAction] = useState({
    data: null,
    action: null,
  });
  // const [snackbarInfo, setSnackbarInfo] = useState({
  //   show: false,
  //   type: '',
  //   message: '',
  // });
  const [openFilters, setOpenFilters] = useState(false);

  const handlePageChange = (v) => {
    setOptions({
      ...options,
      page: v,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRowsPerPageChange = (v) => {
    setOptions({
      ...options,
      rowsPerPage: v,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleSortingChange = (sortBy, sortOrder) => {
    if (options.loading) return;
    setOptions({
      ...options,
      sortBy,
      sortOrder,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRefreshData = () => {
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleActionSelect = (selectedAction, selectedData) => {
    setDoAction({
      ...doAction,
      data: selectedData || null,
      action: selectedAction || null,
    });
  };

  const handleToggleFilters = () => {
    setOpenFilters(!openFilters);
  };
  const handleApplyFilters = (selectedFilters) => {
    let counter = 0;
    if (selectedFilters.search.trim()) counter += 1;

    setOptions({
      ...options,
      appliedFilters: {
        ...selectedFilters,
      },
      filtersCounter: counter,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
    handleToggleFilters();
  };
  const handleClearFilters = () => {
    handleToggleFilters();
    setOptions({
      ...options,
      appliedFilters: {
        search: '',
      },
      filtersCounter: 0,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const updateState = (updatedId, newType) => {
    const newRows = [];
    for (let i = 0; i < options.rows.length; i += 1) {
      let tmpRow = options.rows[i];
      if (tmpRow.subscriberId === updatedId) {
        tmpRow = {
          ...tmpRow,
          type: newType.userReview,
        };
      }
      newRows.push(tmpRow);
    }
    setOptions({
      ...options,
      likeCount: newType.reviews.like,
      disLikeCount: newType.reviews.dislike,
      rows: newRows,
    });
  };

  useEffect(() => {
    const params = [];
    params.push(`page=${options.page + 1}`);
    params.push(`perPage=${options.rowsPerPage}`);
    if (options.sortBy && options.sortOrder) {
      params.push(`sortBy=${options.sortBy}`);
      params.push(`sortOrder=${options.sortOrder}`);
    }
    const filters = options.appliedFilters;
    if (filters.search) {
      params.push(`q=${encodeURIComponent(filters.search)}`);
    }

    const paramsQuery = params.length > 0 ? `?${params.join('&')}` : '';
    getReviewsList(dataId, paramsQuery)
      .then((res) => {
        setOptions({
          ...options,
          loading: false,
          totalRows: res?.data?.totalRows || 0,
          rows: res?.data?.rows || [],
          error: false,
          videoTitle: res?.data?.videoTitle || '',
          likeCount: res?.data?.reviews?.like || options.likeCount,
          disLikeCount: res?.data?.reviews?.dislike || options.disLikeCount,
        });
      })
      .catch(() => {
        setOptions({
          ...options,
          loading: false,
          page: 0,
          totalRows: 0,
          rows: [],
          error: true,
          videoTitle: '',
          likeCount: 0,
          disLikeCount: 0,
        });
      });
  }, [options.reloadCounter]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ px: 1, py: 2 }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: '1em',
          }}
        >
          <Box fontWeight="bold" fontSize="20px">{`Manage Videos ${
            options.videoTitle ? `- ${options.videoTitle}` : ''
          }`}</Box>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '1em',
              width: 'fit-content',
            }}
          >
            <Typography
              sx={{
                display: 'flex',
              }}
            >
              <Iconify
                sx={{ color: '#FFBD5A' }}
                width={30}
                icon="solar:like-bold"
              />
              <Typography
                sx={{
                  fontSize: '30px',
                  lineHeight: 'initial',
                }}
              >
                {' '}
                {options.likeCount || 0}
              </Typography>
            </Typography>
            <Typography
              sx={{
                display: 'flex',
              }}
            >
              <Iconify
                sx={{ color: '#F00' }}
                width={30}
                icon="solar:dislike-bold"
              />
              <Typography
                sx={{
                  fontSize: '30px',
                  lineHeight: 'initial',
                }}
              >
                {options.disLikeCount || 0}
              </Typography>
            </Typography>
          </Box>
        </Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ px: 1, py: 1 }}
        >
          <Button
            color={options.filtersCounter > 0 ? 'warning' : 'inherit'}
            variant="contained"
            startIcon={<Iconify icon="ic:round-filter-list" />}
            sx={{ marginRight: 2 }}
            onClick={handleToggleFilters}
          >
            Filters
            {options.filtersCounter > 0 ? ` (${options.filtersCounter})` : ''}
          </Button>
          <Button
            color="inherit"
            variant="contained"
            startIcon={<Iconify icon="ic:twotone-refresh" />}
            sx={{ marginRight: 2 }}
            onClick={handleRefreshData}
            disabled={options.loading}
          >
            Refresh
          </Button>
          <div>
            <Link to={ROUTE_PATH.CONTENT_VIDEOS}>
              <Button color="primary" variant="contained">
                Back
              </Button>
            </Link>
          </div>
        </Stack>
      </Stack>

      <Card>
        <ListData
          columns={columns}
          rows={options.rows}
          page={options.page}
          rowsPerPage={options.rowsPerPage}
          totalRows={options.totalRows}
          loading={options.loading}
          actions={actions}
          error={options.error}
          sortBy={options.sortBy}
          sortOrder={options.sortOrder}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          onSortChange={handleSortingChange}
          onAction={handleActionSelect}
          updateState={updateState}
          fromPage="reviews"
        />
      </Card>

      <Drawer
        anchor="right"
        open={openFilters}
        onClose={handleToggleFilters}
        PaperProps={{
          sx: { width: 300, border: 'none', overflow: 'hidden' },
        }}
      >
        <FilterReviews
          values={options.appliedFilters}
          onCancel={handleToggleFilters}
          onSuccess={handleApplyFilters}
          onClear={handleClearFilters}
        />
      </Drawer>

      {/* {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )} */}
    </>
  );
};

ManageReviews.propTypes = {
  dataId: PropTypes.number.isRequired,
};

export default ManageReviews;
