import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListPromoCodes from '../../components/promo-codes/list-promo-codes.component';

const PromoCodesView = () => {
  const pageTitle = 'Settings / Promo Codes';

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListPromoCodes />
    </AppLayout>
  );
};

export default PromoCodesView;
