// Define promo offer types
export const PROMO_CODE_OFFERS = [
  {
    id: 'PER_OFF',
    title: 'Percentage (%) OFF',
  },
  {
    id: 'AMT_OFF',
    title: 'Fixed Amount OFF',
  },
];

export const getPromoCodeOfferLabel = (val) => {
  let label = '';

  PROMO_CODE_OFFERS.forEach((p) => {
    if (label === '' && p.id === val) {
      label = p.title;
    }
  });

  return label;
};

export const PROMO_CODE_APPLY_ON = [
  {
    id: 'SVOD',
    title: 'SVOD Only',
  },
  {
    id: 'TVOD',
    title: 'TVOD Only',
  },
  {
    id: 'SVOD_TVOD',
    title: 'Both SVOD and TVOD',
  },
];
