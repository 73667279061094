import React, { useState, useEffect, useRef } from 'react';
import {
  Stack,
  Button,
  TextField,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import {
  BtnBold,
  BtnClearFormatting,
  BtnItalic,
  BtnLink,
  BtnNumberedList,
  BtnStrikeThrough,
  BtnUnderline,
  Editor,
  EditorProvider,
  Toolbar,
} from 'react-simple-wysiwyg';
import SnackbarInfo from '../common/snackbar-info.component';
import { EMAIL_TEMPLATES_TYPE } from '../../config/const.config';
import {
  updateMailData,
  viewMailData,
} from '../../services/smtp-templates.service';
import Loading from '../table-elements/loading.component';
import validationRules, {
  maxLengthValidation,
} from '../../utils/validations.util';

const ListSMTPTemplates = () => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      emailType: EMAIL_TEMPLATES_TYPE.REGISTRATION_EMAIL.value,
      subject: null,
      content: null,
    },
  });

  const [reloadCounter, setReloadCounter] = useState(0);
  const [loader, setLoader] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const serverDataRef = useRef({});
  const emailTemplateWatch = watch('emailType');
  const subjectWatch = watch('subject');
  const contentWatch = watch('content');

  const handleActionSuccess = (message = '') => {
    if (message) {
      setSnackbarInfo({
        ...snackbarInfo,
        show: true,
        type: 'success',
        message,
      });
    }
  };

  const updateExistingData = (payload) => {
    updateMailData(emailTemplateWatch, payload)
      .then(() => {
        setLoader(false);
        handleActionSuccess('Email content updated successfully.');
        setReloadCounter(reloadCounter + 1);
        setFormSubmitted(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setLoader(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {
      subject: data.subject,
      content: data.content,
    };
    updateExistingData(payload);
  };

  useEffect(() => {
    viewMailData(emailTemplateWatch)
      .then((res) => {
        if (res.data) {
          setValue('subject', res.data?.subject || null);
          setValue('content', res.data?.content || null);
          serverDataRef.current = res.data;
        }

        setLoader(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoader(false);
      });
  }, [emailTemplateWatch, reloadCounter]);

  return (
    <>
      <Stack direction="row" sx={{ py: 2 }}>
        <Box width="30%">
          <Controller
            name="emailType"
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormControl fullWidth variant="standard">
                <InputLabel id="field-status-select-label">Template</InputLabel>
                <Select
                  id="email_type"
                  name="email_type"
                  label="Template"
                  value={value}
                  onChange={onChange}
                  sx={{ width: '100%' }}
                >
                  {Object.values(EMAIL_TEMPLATES_TYPE).map((item) => (
                    <MenuItem value={item.value}>{item.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Box>
      </Stack>

      {loader && <Loading />}

      {!loader && (
        <>
          <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="subject"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...maxLengthValidation(500),
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Subject"
                      type="text"
                      error={!!errors.subject}
                      helperText={errors?.subject?.message || null}
                      fullWidth
                      variant="filled"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="content"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <EditorProvider>
                      <Editor
                        value={value}
                        onChange={onChange}
                        style={{
                          minHeight: '400px',
                          maxHeight: '550px',
                          overflow: 'auto',
                        }}
                      >
                        <Toolbar>
                          <Box
                            padding="0px 10px"
                            color="#637381"
                            borderRight="1px solid #637381"
                            fontSize="15px"
                          >
                            Content
                          </Box>
                          <BtnBold />
                          <BtnItalic />
                          <BtnUnderline />
                          <BtnStrikeThrough />
                          <BtnNumberedList />
                          <BtnLink />
                          <BtnClearFormatting />
                        </Toolbar>
                      </Editor>
                    </EditorProvider>
                  )}
                />
              </Grid>
            </Grid>
          </form>

          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ px: 1, py: 2, mt: 2 }}
          >
            <Box display="flex">
              <div>
                <Button
                  type="submit"
                  form="add-edit-form"
                  color="primary"
                  variant="contained"
                  disabled={loader || formSubmitted}
                >
                  Save
                </Button>
              </div>
              <div>
                <Button
                  color="inherit"
                  variant="contained"
                  onClick={() => setReloadCounter(reloadCounter + 1)}
                  disabled={
                    loader ||
                    (serverDataRef.current.content === contentWatch &&
                      serverDataRef.current.subject === subjectWatch)
                  }
                  sx={{ ml: 2 }}
                >
                  Reset Changes
                </Button>
              </div>
            </Box>
            <div>
              <Typography
                variant="body1"
                color="secondary"
                sx={{ textDecoration: 'underline' }}
              >
                Notes for placeholders
              </Typography>
              <Typography variant="body2" color="secondary">
                {`{subscriberName} - To replace subscriber full name`}
              </Typography>
              <Typography variant="body2" color="secondary">
                {`{subscriberUsername} - To replace subscriber username`}
              </Typography>
              <Typography variant="body2" color="secondary">
                {`{subscriberEmail} - To replace subscriber email`}
              </Typography>
              <Typography variant="body2" color="secondary">
                {`{videoTitle} - To replace video title (Only for purchase mail)`}
              </Typography>
              <Typography variant="body2" color="secondary">
                {`{platformMessage} - To replace specific platform wise message (Only for reset password mail)`}
              </Typography>
              <Typography variant="body2" color="secondary">
                {`{linkCode} - To replace reset password link or code (Only for reset password mail)`}
              </Typography>
            </div>
          </Stack>
        </>
      )}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};

export default ListSMTPTemplates;
